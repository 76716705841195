.user-page-container {
  margin: 5em 2em;
}

.avatar-image_body {
  vertical-align: middle;
  margin-right: 10px;
}

.p-avatar-image img {
  vertical-align: middle;
}

.avatar-text_body {
  margin-left: 0px;
}

.avatar-container_header {
  display: none;
  visibility: collapse;
}

@media (max-width: 960px) {

  .avatar-container-header_body {
    display: none;
    visibility: collapsed;
  }

  .avatar-container_body {
    display: none;
    visibility: collapse;
  }

  .avatar-container_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: visible;
    padding: 0.5rem;
  }

  .avatar-image_header {
    visibility: visible;
    margin-right: 42%;
    margin-bottom: 10px;
    margin-top: 10px;
    width: fit-content;
    margin: 0;
  }
  
  .avatar-text_header {
    margin: 0;
    padding-top: 0.5rem;
  }

}