/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');*/
@import "primeflex/primeflex.css";

* {
  box-sizing: border-box;
  /*font-family: 'Titillium Web';*/
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

html {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-image: linear-gradient(180deg, #00DBDE 0%, #FC00FF 100%);
  background-repeat: no-repeat;
  max-width: 100%;
}


.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button {
  margin-left: 2%;
  margin-right: 2%;
}

.icon-button icon {
  font-size: 1rem;
}

.home-panel.p-panel {
  border-color: #54D5E1;
}

.p-panel .p-panel-title {
  color: #54D5E1;
}