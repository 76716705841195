.inConstruction__container {
  width: 100%;
  height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inConstruction__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inConstruction__header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inConstruction__title {
  position: absolute;
  z-index: 999;
  font-size: 3em;
  font-weight: 500;
}

.inConstruction__icon {
  font-size: 15em;
  z-index: 1;
  color: rgb(152,87,242);
}

.inConstruction__subheader {
  margin: 0;
  font-size: 1em;
  font-weight: 500;
}

@media (min-width: 960px) {

  .inConstruction__title {
    font-size: 5em;
  }

  .inConstruction__icon {
    font-size: 30em;
  }

}