.card-widget_container {
  margin: 1rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(29, 30, 46, 0.5);
}

.card-widget_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  width: 20rem !important;
}

.card-widget_avatar {
  width: 5rem;
  height: 5rem;
}