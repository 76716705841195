.datatable-rowgroup-demo .p-rowgroup-footer td {
  font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
  font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
  vertical-align: middle;
  margin-right: .25rem;
}

.datatable-rowexpansion-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-rowexpansion-demo .orders-subtable {
  padding: 1rem;
}

.action-button {
  margin-left: 5px;
  width: 15%;
}

.table-header {
  display: flex;
}

.table-menu-actions {
  margin-right: 10px;
}



.icon-margin {
  margin-left: 5px;
}

.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .datatable-crud-demo .table-header {
      align-items: flex-start;
  }
}
.datatable-crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  .datatable-crud-demo .p-toolbar {
      flex-wrap: wrap;
  }
  .datatable-crud-demo .p-toolbar .p-button {
      margin-bottom: 0.25rem;
  }
  .datatable-crud-demo .table-header {
      flex-direction: column;
  }
  .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
      width: 100%;
  }
}

.datatable-rowgroup-demo .p-rowgroup-footer td {
  font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
  font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
  vertical-align: middle;
  margin-right: .25rem;
}

.datatable-rowexpansion-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-rowexpansion-demo .orders-subtable {
  padding: 1rem;
}

.row-actions-container {
  display: flex;
}

.action-button {
  margin-left: 5px;
  width: 15%;
}

.table-header {
  display: flex;
}

.table-menu-actions {
  margin-right: 10px;
}



.icon-margin {
  margin-left: 5px;
}

.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .datatable-crud-demo .table-header {
      align-items: flex-start;
  }
}
.datatable-crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  .datatable-crud-demo .p-toolbar {
      flex-wrap: wrap;
  }
  .datatable-crud-demo .p-toolbar .p-button {
      margin-bottom: 0.25rem;
  }
  .datatable-crud-demo .table-header {
      flex-direction: column;
  }
  .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
      width: 100%;
  }
}